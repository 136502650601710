.jitsi-meet-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;

  >div {
    height: 100%;
  }

  .bg-primary.bg-soft {
    background-color: rgba(85, 110, 230, .25) !important;
  }
}